#App {
  background-color: #eee;
  min-height: 100vh;
}
#App .tab {
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  line-height: 90px;
  background-color: #00a0e9;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 0 40px;
}

#App .currentBox {
  margin: 20px 45px;
}
#App .currentBox p {
  color: #333;
  font-weight: 700;
  line-height: 50px;
  border-bottom: 1px solid #999;
  font-size: 18px;
}
#App .currentBox span {
  font-weight: 400;
}

#App .waitingBox {
  margin: 40px 45px;
  background-color: #eee;
}
#App .waitingBox p {
  color: #333;
  font-weight: 700;
  line-height: 50px;
  border-bottom: 1px solid #999;
  font-size: 18px;
}
#App .waitingBox .total {
  font-weight: 400;
  font-size: 17px;
}

#App .waitingBox .ant-table-pagination.ant-pagination {
  margin: 20px 0 30px;
}

#App .waitingBox .ant-table-wrapper {
  margin: 0 !important;
}
